import { WaGreen } from "../../assets/assets";
import "./FloatingWa.css"

const FloatingWa = ({states}:any) =>{
    const windowWidthClass = ((states && states.windowWidthClass))|| "window-pc";
    const openLinkHandler = (link:any) =>{
        // console.log("link", link)
        window.open(link, '_blank');
    }
    return(
        <div className={`${windowWidthClass}-section-floating-wa`}
        onClick={()=>
            openLinkHandler(`https://wa.me/62811905757?text=Hi,%20I'd%20like%20to%20know%20more%20%20about%20this%20program.%20Thank%20you.%20`)
        }
        >
            <img src={WaGreen} alt="circle green whatsapp floating icon" />
        </div>
    )
};

export default FloatingWa;