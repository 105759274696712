import { useNavigate } from "react-router-dom";
import "./AppFooterNew.css";
import { ImgMail, ImgWa } from "../../assets/assets";

const AppFooterNew = ({states}:any) =>{
    // const windowWidthClass = (states && states.windowWidthClass) || "";
    const classWidth = (states && states.windowWidthClass) || "";
    const reorder = classWidth === "window-mob" ? classWidth : "window-pc"
    const windowWidthClass = "window-pc";
    const navigate = useNavigate();
    const handleNavbar = (path:string) =>{
        const fullpath = path || "/"
        navigate(fullpath)
    }

    const openLinkHandler = (link:any) =>{
        // console.log("link", link)
        window.open(link, '_blank');
    }
    return(
        <>
            <section className={`${reorder}-app-footer-new`}>
                <div className={`${windowWidthClass}-app-footer-new-item  ${reorder}-footer-child-1`}>
                    <div className={`${windowWidthClass}-item-address-wrap`}>
                        <a onClick={()=>handleNavbar("/")}>MATH Care</a>
                        <p>Pondok Indah Office Tower 2, 15th Floor</p>
                        <p>Jl. Sultan Iskandar Muda Kav. V-TA</p>
                        <p>Jakarta Selatan 12310</p>
                    </div>
                </div>

                <div className={`${windowWidthClass}-app-footer-new-item  ${reorder}-footer-child-2`}>
                    <div className={`${reorder}-item-anchors-wrap`}>
                        <div className={`${windowWidthClass}-item-anchors-wrap-item`}>
                            <p>Services</p>
                            <div className="service-wrap"
                            onClick={()=>
                                openLinkHandler(`https://wa.me/62811905757?text=Hi,%20I'd%20like%20to%20know%20more%20%20about%20this%20program.%20Thank%20you.%20`)
                            }
                            >
                                <a> IB (International Baccalaurate) </a>
                                <a> AP (Advanced Placement)</a>
                                <a> Cambridge Curriculum </a>
                                <a> US Curriculum</a>
                                <a> Singapore Math</a>
                                <a> Test Preparation</a>
                                <a> Math Competition</a>
                            </div>
                        </div>
                        <div className={`${windowWidthClass}-item-anchors-wrap-item`}>
                            <p>Contacts</p>
                            <div className="wrap-wrap"
                            onClick={()=>
                                openLinkHandler(`https://wa.me/62811905757?text=Hi,%20I'd%20like%20to%20know%20more%20%20about%20this%20program.%20Thank%20you.%20`)
                            }
                            >
                                <img src={ImgWa}/>
                                <a>+62811905757 </a>
                            </div>
                            <div className="wrap-wrap">
                                <img src={ImgMail}/>
                                <a>hello@indomathcare.com</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
};

export default AppFooterNew;