import { ImgSectionBanner } from "../../assets/assets";
import "./ContainerLandingPage.css";
const ContainerLandingPage = ({states}:any)=>{
    const windowWidthClass = ((states && states.windowWidthClass))|| "window-pc";

    return ( 
        <section className={`${windowWidthClass}-container-landing-page-section-banner display-flex`}>

                <div className={`
                ${windowWidthClass}-container-landing-page-section-banner-item 
                ${windowWidthClass}-container-landing-page-section-banner-item-tagline`}>
                    <h1 className={`
                    ${windowWidthClass}-container-landing-page-section-banner-item-tagline-title`}>MATH Care</h1>
                    <h3 className={`
                    ${windowWidthClass}-container-landing-page-section-banner-item-tagline-desc`}>Ignite Your Potential in Math</h3>
                    {/* <h1>test</h1> */}
                </div>

                <div className={`
                ${windowWidthClass}-container-landing-page-section-banner-item  
                ${windowWidthClass}-container-landing-page-section-banner-item-image`}>
                    <figure className={`
                    ${windowWidthClass}-container-landing-page-section-banner-item-image-fig`}>
                        <img className={`
                        ${windowWidthClass}-container-landing-page-section-banner-item-image-img`} src={ImgSectionBanner} alt="Description of the image"/>
                    </figure>
                </div>
            </section>
        
    )
};

export default ContainerLandingPage;