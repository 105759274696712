import { useEffect, useState } from "react";
import { ImgTestimoni } from "../../assets/assets";
import { AppFooterNew, AppNavbar, FloatingWa } from "../../components";
import "./Testimonials.css";
import { useWindowSize } from "@uidotdev/usehooks";

const TestimonialsPage = () =>{
    const [windowWidthClass, setWindowWidthClass] = useState("window-pc");
    const size = useWindowSize();

    useEffect(()=>{
        const windowWidth = size.width as number;
        if(windowWidth < 670 && windowWidth !== null) {
            setWindowWidthClass("window-mob");
        } else if (windowWidth < 750 && windowWidth !== null) {
            setWindowWidthClass("window-tab");
        } else {
            setWindowWidthClass("window-pc");
        };
    }, [size])

    return (
        <>
            <div className="app-pages service-page">
                <AppNavbar/>
                <TestimoniScreenQuotes/>
                <AppFooterNew states={{windowWidthClass}}/>
            </div>
            <FloatingWa states={{windowWidthClass}}/>
        </>
    )
};

export default TestimonialsPage;

const TestimoniScreenQuotes = () =>{
    const [windowWidthClass, setWindowWidthClass] = useState("window-pc");
    const size = useWindowSize();
    const curriculums = [
    {
        name: "Mrs. Catherine Chaperon, mom of Mathieu Chaperon, who improved his IB Math score from 2 to 6",
        desc:
        `
        My son started his tuitions a few months before the final IB exams and his results drastically improved. Thanks to Mrs. Emilia and her tutors who could immediately pointed my son's weaknesses and were able to fill his gaps by explaining every subject and practice past papers.
My son was not stressed by his exams as he was very well prepared and was able to do his tests in very good conditions.

        `
    },
    {
        name: "Dakota Sinder, JIS high school",
        desc: `
        I wanted to take a moment to not only thank you for helping me with my SAT review but also for pretty much being there for my whole journey through math in high school. You've helped me time after time overcome what I consider to be my most difficult subject.
        `
    },
    {
        name: "Mrs. Jenny Hartono, mom of Nathan (from JIS to UC Berkeley) and Michelle (JIS)",
        desc: "Thank you for your support and guidance to Nathan and Michelle all these years.  They have been blessed with such strong support team from MATH Care that makes such a big difference in their math classes.  Most importantly, they learn to appreciate the importance of math in everyday applications.  As parents, we are confident they are well prepared to take on their next challenges in life."
    },
    {
        name: "Mrs. Shobha, mom of Hannah, JIS grade 8-12 ",
        desc: "It was a long journey with you, Ms Anas, and Ms Linda, thank you so much to you and both teachers for being so helpful and making her school year in maths go smoothly! We are so thankful."
    }
]
    useEffect(()=>{
        const windowWidth = size.width as number;
        if(windowWidth < 800 && windowWidth !== null) {
            setWindowWidthClass("window-mob");
        } else {
            setWindowWidthClass("window-pc");
        };
    }, [size]);
    return (
        <>
            <div className="testimoni-page testimoni-screen-quote">
                <h1>From Parents and Students</h1>
                <div className={ (windowWidthClass==="window-pc"? "window-pc-testimoni-card-box":"testimoni-card-box")}>
                    {
                        curriculums.map(x => {
                            return (
                                <>
                                <div className="testimoni-item">
                                    {/* <div className="curr-bubble"> */}
                                        <figure className="testimoni-item-fig">
                                            <img src={ImgTestimoni} className="testimoni-item-img" alt="yellow circle contains bubble message"/>
                                        </figure>
                                        <section className="testimoni-item-text-section">
                                            <p className="testimoni-item-text-value">{x.desc}</p>
                                            <p className="testimoni-item-text-desc">{x.name}</p>
                                        </section>
                                    {/* </div> */}
                                </div>
                                </>
                            )
                        })
                    }
                    
                </div>
            </div>
        </>
    )
};
