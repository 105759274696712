import { ImgEarthGrid, ImgSuccess, ImgTutor1on1 } from "../../assets/assets";
import "./ContainerLandingBelt.css"


const ContainerLandingBelt = ({states}:any) =>{
    const windowWidthClass = ((states && states.windowWidthClass))|| "";

    return(
        <>
            <section className={`${windowWidthClass}-container-landing-belt-section`}>
                <div className={
                    `${windowWidthClass}-container-landing-belt-section-item`}>
                    <img className="" src={ImgEarthGrid} />
                    <p className="core-item-text-value">International Curriculums</p>
                </div>
                <div className={
                    `${windowWidthClass}-container-landing-belt-section-item`}>
                    <img className="" src={ImgTutor1on1} />
                    <p className="core-item-text-value">1-on-1 Personalized Tutoring</p>
                </div>
                <div className={
                    `${windowWidthClass}-container-landing-belt-section-item`}>
                    <img className="" src={ImgSuccess} />
                    <p className="core-item-text-value">Proven Track Record of Success</p>
                </div>
            </section>
        </>
    )
};

export default ContainerLandingBelt;