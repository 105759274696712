import { useEffect, useState } from "react";
import { ImgMath } from "../../assets/assets";
import { AppFooterNew, AppNavbar, FloatingWa } from "../../components";
import "./ServicePage.css";
import { useWindowSize } from "@uidotdev/usehooks";

const ServicePage = () =>{
    const [windowWidthClass, setWindowWidthClass] = useState("window-pc");
    const size = useWindowSize();

    useEffect(()=>{
        const windowWidth = size.width as number;
        if(windowWidth < 670 && windowWidth !== null) {
            setWindowWidthClass("window-mob");
        } else if (windowWidth < 750 && windowWidth !== null) {
            setWindowWidthClass("window-tab");
        } else {
            setWindowWidthClass("window-pc");
        };
    }, [size])

    return (
        <>
            <div className="app-pages service-page">
                <AppNavbar/>
                <ServiceScreenCurr/>
                <AppFooterNew states={{windowWidthClass}}/>
            </div>
            <FloatingWa states={{windowWidthClass}}/>
        </>
    )
};

export default ServicePage;

const ServiceScreenCurr = () =>{
    const openLinkHandler = (link:any) =>{
       
        window.open(link, '_blank');
    }
    const curriculums = [
    {
        name: "IB (International Baccalaurate)",
        desc: "IB PYP, IB MYP, IB Math AI SL, IB Math AI HL, IB Math AA SL, IB Math AA HL"
    },
    {
        name: "AP (Advanced Placement)",
        desc: "AP Calculus AB, AP Calculus BC, AP Statistics"
    },
    {
        name: "Cambridge Curriculum",
        desc: "Cambridge Primary Checkpoint, Cambridge Secondary Checkpoint, IGCSE, Additional Mathematics, O Level, AS/A Level"
    },
    {
        name: "US Curriculum",
        desc: "including Honors courses"
    },
    {
        name: "Singapore Math",
        desc: "CPA (Concrete - Pictorial - Abstract)"
    },
    {
        name: "Test Preparation",
        desc: "SAT, ACT, PSAT, SSAT, GMAT, GRE"
    },
    {
        name: "Math Competition",
        desc: "WMI, SASMO"
    },
    
]
    return (
        <>
            <div className="testimoni-page testimoni-screen-quote">
                <h1>Programs We Can Teach</h1>
                <div className="curriculum-card-box">
                    {
                        curriculums.map(x => {
                            return (
                                <>
                                <div className="curriculum-item">
                                    {/* <div className="curr-bubble"> */}
                                        <figure className="curr-item-fig" onClick={()=>
                                            openLinkHandler(`https://wa.me/62811905757?text=Hi,%20I'd%20like%20to%20know%20more%20%20about%20this%20program.%20Thank%20you.%20`)
                                        }>
                                            <img src={ImgMath} className="curr-item-img" alt="yellow circle contains mathematical function"/>
                                        </figure>
                                        <section className="curr-item-text-section">
                                            <p className="curr-item-text-value">{x.name}</p>
                                            <p className="curr-item-text-desc">{x.desc}</p>
                                        </section>
                                    {/* </div> */}
                                </div>
                                </>
                            )
                        })
                    }
                    
                </div>
            </div>
        </>
    )
};
