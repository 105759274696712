import { useEffect, useState } from "react";
import { AppFooterNew, AppNavbar, FloatingWa } from "../../components";
import "./LandingPage.css";
import { useWindowSize } from "@uidotdev/usehooks";
import { ContainerLandingBanner, ContainerLandingBelt, ContainerLandingZig } from "../../containers";

const LandingPage = () =>{
    const [windowWidthClass, setWindowWidthClass] = useState("window-pc");
    const size = useWindowSize();

    useEffect(()=>{
        const windowWidth = size.width as number;
        if(windowWidth < 670 && windowWidth !== null) {
            setWindowWidthClass("window-mob");
        } else if (windowWidth < 749 && windowWidth !== null) {
            setWindowWidthClass("window-tab");
        } else {
            setWindowWidthClass("window-pc");
        };
    }, [size])

    return (
        <>
            <div className="app-pages landing-pages">
                <AppNavbar/>
                <ContainerLandingBanner states={{windowWidthClass}}/>
                <ContainerLandingBelt states={{windowWidthClass}}/>
                <ContainerLandingZig states={{windowWidthClass}}/>
                <AppFooterNew states={{windowWidthClass}}/>
                
            </div>
    <FloatingWa states={{windowWidthClass}}/>
        </>
    )
};

export default LandingPage;
