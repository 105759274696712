import { ImgCoreExcel, ImgCoreFlexi1, ImgCoreMath2, ImgCorePerson1, ImgCoreSuccess1, ImgSectionBanner } from "../../assets/assets";
import "./ContainerLandingZig.css";

const ContainerLandingZig = ({states}:any) =>{

    const windowWidthClass = (states && states.windowWidthClass) || ""
    let core2Data = [
       { 
            title: "Excellence",
            desc: "We are committed to delivering the highest quality education by fostering a good learning environment with experienced and passionate tutors. We continuously strive to improve our curriculum and teaching methods to ensure our students are well-equipped for success.",
            imgSrc: ImgCoreExcel
       },
       { 
            title: "Individualized Learning",
            desc: "We believe that every student learns differently. We tailor our instruction to each student's unique needs and learning style, creating personalized lesson plans that address their strengths and weaknesses. This ensures a deeper understanding of mathematical concepts and fosters a love of learning.",
            imgSrc: ImgCorePerson1
       },
       { 
            title: "Passion for Math",
            desc: "We believe that mathematics is not just a subject, but a gateway to critical thinking and problem-solving skills. Our enthusiastic tutors cultivate a positive learning environment that ignites a passion for math in our students, encouraging them to explore and discover the beauty and power of mathematical concepts.",
            imgSrc: ImgCoreMath2
       },
       { 
            title: "Student Success",
            desc: "We are dedicated to the academic success of each student. Our experienced tutors provide the guidance and support needed to excel in their math courses and achieve their academic goals, including standardized test scores and college admissions.",
            imgSrc: ImgCoreSuccess1
       },
       {
            title: "Flexibility and Convenience",
            desc: "We understand that busy schedules require flexible learning options. We offer in-person tutoring at our Pondok Indah center, convenient home tutoring, or online learning via Zoom to accommodate individual preferences and needs.",
            imgSrc: ImgCoreFlexi1
       }

    ]
    return(
        <>
            <section className={`${windowWidthClass}-container-landing-ziggies`}>
                <h1>How Will We Help</h1>
                <div className={`${windowWidthClass}-container-landing-ziggies-box`}>
                    {
                        core2Data ? 
                        core2Data.map((x:any, index:any)=>{
                            const {title, desc, imgSrc} = x;
                            const uniqueKey = index+title;
                            return(
                                <div key={uniqueKey} className={`${windowWidthClass}-container-landing-ziggies-box-item`}>
                                  
                                        <img key={uniqueKey+"img"}  src={imgSrc} alt={title}/>
                                    
                                    {
                                        windowWidthClass === "window-pc" ? 
                                        <div key={uniqueKey+"div"} >
                                            <h3>{title}</h3>
                                            <p>{desc}</p>
                                        </div> : 
                                        <>
                                            <h3>{title}</h3>
                                            <p>{desc}</p>
                                        </>
                                    }
                                    
                                    
                                </div>)
                        }) 
                        : null
                    }
                </div>
            </section>
        </>
    )
};

export default ContainerLandingZig;