import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ContactUs, LandingPage, ServicePage, TestimonialsPage } from './pages';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
            <Route path="/" element={<LandingPage/>}/>
            <Route path="/services" element={<ServicePage/>}/>
            <Route path="/testimonials" element={<TestimonialsPage/>}/>
            <Route path="/contact-us" element={<ContactUs/>}/>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
