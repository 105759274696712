import { useEffect, useState } from "react";
import { ImgMath2 } from "../../assets/assets";
import { AppFooterNew, AppNavbar, FloatingWa } from "../../components";
import "./ContactUs.css";
import { useWindowSize } from "@uidotdev/usehooks";

const ContactUs = () =>{
    const [windowWidthClass, setWindowWidthClass] = useState("window-pc");
    const size = useWindowSize();

    useEffect(()=>{
        const windowWidth = size.width as number;
        if(windowWidth < 670 && windowWidth !== null) {
            setWindowWidthClass("window-mob");
        } else if (windowWidth < 750 && windowWidth !== null) {
            setWindowWidthClass("window-tab");
        } else {
            setWindowWidthClass("window-pc");
        };
        // console.log("lp-ue-", windowWidthClass, windowWidth)
    }, [size])

    return (
        <>
            <div className="app-pages service-page">
                <AppNavbar/>
                <ContactUsScreen/>
                <AppFooterNew states={{windowWidthClass}}/>
            </div>
            <FloatingWa states={{windowWidthClass}}/>
        </>
    )
};

export default ContactUs;

const ContactUsScreen = () =>{
    const openLinkHandler = (link:any) =>{
        // console.log("link", link)
        window.open(link, '_blank');
    }
    const curriculums = [
    {
        title: "Empowering Students in Mathematics",
        paragraphs: [
            "MATH Care is a premier educational center dedicated to unlocking the potential of students in Mathematics. We provide comprehensive support for students from elementary to high school, fostering a deep understanding of mathematical concepts and propelling them towards academic success.",
            // "Our passionate tutors ignite a love of learning math in our students. Highly qualified tutors with a proven track record of success guide your child on their math journey, from mastering the fundamentals to preparing for college entrance exams like SAT, ACT, and more.",
            {
                title: "Tailored Learning for Diverse Needs",
                paragraphs: [
                    "Extensive Subject Coverage: Our curriculum encompasses a wide range of programs, including IB Math (AI/AA, SL/HL), AP Calculus/Statistics, Cambridge IGCSE/Additional Math/O/AS/A Level, US Honors courses, Singapore Math, and more.",
                    "Test Preparation Expertise: We offer specialized programs for standardized tests like SAT, ACT, PSAT, SSAT, GMAT, and GRE, equipping students for admissions success at top universities worldwide."
                ]
            },
            {
                title: "A Legacy of Excellence",
                paragraphs: [
                    "Our proven track record speaks for itself. We are proud to have guided students to admission at Ivy League and top-tier universities across the US, Europe, Asia, and Australia.",
                    // "We offer in-person tutoring at our Pondok Indah learning center, online tutoring via Zoom, or tutoring in the comfort of your own home."
                ]
            },
            {
                title: "Cultivating a Passion for Math",
                paragraphs: [
                    "We believe that mathematics is not just a subject, but a gateway to critical thinking and problem-solving skills. Our enthusiastic tutors create an engaging and personalized learning environment that fosters an in-depth comprehension of mathematical concepts. This approach empowers students to not only apply their knowledge but also develop a genuine love for the subject and its applications.",
                    "Individualized Instruction: We offer private, one-on-one tutoring with customized lesson plans tailored to individual needs and goals. Students receive focused attention, ensuring a deeper understanding of concepts.",
                    "Flexible Learning Options: Choose in-person sessions at our Pondok Indah center, convenient home tutoring, or online learning via Zoom for maximum flexibility."
                    // "We offer in-person tutoring at our Pondok Indah learning center, online tutoring via Zoom, or tutoring in the comfort of your own home."
                ]
            },
            {
                title: "Ignite Your Potential in Math. Contact Us Today!",
                paragraphs: ""
            }
        ]
    }
]
    
    return (
        <>
            <div className="contactus-page contactus-screen-quote">
                <h1 onClick={()=>
                                                                            openLinkHandler(`https://wa.me/62811905757?text=Hi,%20I'd%20like%20to%20know%20more%20%20about%20this%20program.%20Thank%20you.%20`)}>MATH Care</h1>
                <div className="contactus-card-box">
                    {
                        curriculums.map(x => {
                            const paragraphs =(x && x.paragraphs) || []
                            return (
                                <>
                                <div className="contactus-item">
                                    {/* <div className="curr-bubble"> */}
                                        <figure className="contactus-item-fig">
                                            <img src={ImgMath2} className="contactus-item-img" alt="yellow circle contains rulers and calculators"/>
                                        </figure>
                                        <section className="contactus-item-text-section">
                                            <p className="contactus-item-text-value">{x.title}</p>
                                            {
                                                paragraphs.map((p2:any) => {
                                                    const {title, paragraphs} = p2;
                                                    const isP = !title;
                                                    const paragraphList = paragraphs || [];

                                                    const lastValueStyle = title === "Ignite Your Potential in Math. Contact Us Today!"? "last-value-style" : "";
                                                    return(
                                                        <>
                                                            {
                                                                isP ?
                                                                <p className="contactus-item-text-desc">{p2}</p> : 
                                                                <>
                                                                    <p className={"contactus-item-text-value " + lastValueStyle} onClick={
                                                                        !lastValueStyle?
                                                                        ()=>null
                                                                        :(()=>
                                                                            openLinkHandler(`https://wa.me/62811905757?text=Hi,%20I'd%20like%20to%20know%20more%20%20about%20this%20program.%20Thank%20you.%20`))
                                                                        }>{title}</p>
                                                                    {
                                                                        paragraphs ?  paragraphList.map((p3:any)=>{
                                                                            return(
                                                                                <>
                                                                                    <p className="contactus-item-text-desc">{p3}</p>
                                                                                </>
                                                                            )
                                                                        }) 
                                                                        : ""
                                                                    }
                                                                </>
                                                               
                                                            }
                                                        </>
                                                    )
                                                })
                                            }
                                            <p className="final-p">Ready to embark on a rewarding journey of mathematical exploration? Contact us at <a onClick={()=>
                                                                            null}>hello@indomathcare.com</a> or <a onClick={()=>
                                                                            openLinkHandler(`https://wa.me/62811905757?text=Hi,%20I'd%20like%20to%20know%20more%20%20about%20this%20program.%20Thank%20you.%20`)} className="phone">+62811905757</a> to learn more.</p>
                                        </section>
                                    {/* </div> */}

                                </div>
                                </>
                            )
                        })
                    }

                    
                    
                </div>
            </div>
        </>
    )
};
