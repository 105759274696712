import { useEffect, useState } from "react";
import "./AppNavbar.css";
import { useNavigate } from "react-router-dom";
import { ImgCloseX, ImgMathCareLogo } from "../../assets/assets";
import { useWindowSize } from "@uidotdev/usehooks";



const AppNavbar = () =>{
    const [isScrolled, setIsScrolled] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const[isBurgerCollapsed, setIsBurgerCollapsed] = useState(false);
    const [windowWidthClass, setWindowWidthClass] = useState("window-pc");

    // hooks;
    const navigate = useNavigate();
    const size = useWindowSize();

    // handlers;
    const handleNavbar = (path:string) =>{
        const fullpath = path || "/"
        navigate(fullpath);
    }
    const handleBurger = ()=>{
        setIsBurgerCollapsed(!isBurgerCollapsed);
    }
    const handleKeyPress = (e:any) => {
        if (e.key === 'Enter') {
            // Call a function or submit a form here
            console.log('enter key')
        }
    }
    useEffect(()=>{

        // const windowWidth = size.width as number;
        // console.log("ue", size, (windowWidth < 500) )

        const windowWidth = size.width as number;
        
        if(windowWidth < 600 && windowWidth !== null) {
            setIsMobile(true);
            setWindowWidthClass("window-mob");
            // setIsBurgerCollapsed(false);
        } else if (windowWidth < 760 && windowWidth !== null) {
            setIsMobile(false)
            setWindowWidthClass("window-tab");
            setIsBurgerCollapsed(false);
        } else {
            setWindowWidthClass("window-pc");
            setIsBurgerCollapsed(false);
        }
        
        const handleScroll = () => {
            const isScrolled = window.scrollY > 0;
            setIsScrolled(isScrolled);
            // setDynamicStyleAppNavbarInput(styleAppNavbarInput) : setDynamicStyleAppNavbarInput(styleAppNavbarInputCentered);
        };

        window.addEventListener('scroll', handleScroll);

        return ()=>{
             window.removeEventListener('scroll', handleScroll);
        }
    }, [size])
    return(
        <>
            <nav className={"navbar-nav " + (isScrolled ? " navbar-fixed" : " ")}>
                <section className="nav-section-logo">
                    {/* <a href="#" onClick={()=>{handleNavbar("/")}}>MATH Care</a> */}
                    <img alt="logo math care" className="nav-section-logo-img" src={ImgMathCareLogo}  onClick={()=>{handleNavbar("/")}}/>
                </section>
                {
                    (()=>{
                        switch(windowWidthClass){
                        case "window-pc":
                            return (<>
                            <section className={(windowWidthClass) + "-nav-section-anchors nav-section-anchors" }>
                                <a href="#" onClick={()=>{handleNavbar("/")}}>Home</a>
                                <a href="#" onClick={()=>{handleNavbar("/services")}}>Services</a>
                                <a href="#" onClick={()=>{handleNavbar("/testimonials")}}>Testimonials</a>
                                <a href="#" onClick={()=>{handleNavbar("/contact-us")}}> Contact us</a>
                            </section>
                            </>);
                        case "window-tab":
                            return (<>
                            <section className={(windowWidthClass) + "-nav-section-anchors" }>
                                <a href="#" onClick={()=>{handleNavbar("/")}}>Home</a>
                                <a href="#" onClick={()=>{handleNavbar("/services")}}>Services</a>
                                <a href="#" onClick={()=>{handleNavbar("/testimonials")}}>Testimonials</a>
                                <a href="#" onClick={()=>{handleNavbar("/contact-us")}}> Contact us</a>
                            </section>
                            </>);
                        case "window-mob":
                            return (<>
                                <section className="burger-section" onClick={handleBurger}>
                                    <div className="burger-wrap">
                                        <div className="burger-section-div burger-bun-1"></div>
                                        <div className="burger-section-div burger-bun-2"></div>
                                        <div className="burger-section-div burger-bun-3"></div>
                                        
                                    </div>
                                </section>
                            </>);
                    }})()
                }
                {/* <section className={(windowWidthClass) + "-nav-section-anchors nav-section-anchors" }>
                    <a href="#" onClick={()=>{handleNavbar("/")}}>Home</a>
                    <a href="#" onClick={()=>{handleNavbar("/services")}}>Services</a>
                    <a href="#" onClick={()=>{handleNavbar("/testimonials")}}>Testimonials</a>
                    <a href="#" onClick={()=>{handleNavbar("/contact-us")}}> Contact us</a>
                </section> */}
            </nav>  
            {
                isBurgerCollapsed ? 
                <div className='burger-collapsed' >
                    <section className={(windowWidthClass) + "-nav-section-anchors" }>
                                <a href="#" onClick={()=>{handleNavbar("/")}}>Home</a>
                                <a href="#" onClick={()=>{handleNavbar("/services")}}>Services</a>
                                <a href="#" onClick={()=>{handleNavbar("/testimonials")}}>Testimonials</a>
                                <a href="#" onClick={()=>{handleNavbar("/contact-us")}}> Contact us</a>
                                <div className="burger-collapsed-close" onClick={handleBurger}>
                                    <div className="cross">

                                    </div>
                                </div>
                    </section>

                </div> : 
                null
            }
        </>
    )
};

export default AppNavbar;